import React from "react";
import { NavLink } from "react-router-dom";

export const UnixToDate = ({ unixDate }) => {
    function pad(n) {
        let r;
        if (n < 10) {
            r = `0${n}`;
        } else {
            r = n;
        }
        return r;
    };

    function formatDate(unixDate) {
        const date = new Date(unixDate * 1000);
        return `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`;
    };

    const formattedDate = formatDate( unixDate );

    return (
        <span className='date'>{formattedDate}</span>
    )
};

export const UnixToFormattedDate = ({ unixDate }) => {
    function pad(n) {
        let r;
        if (n < 10) {
            r = `0${n}`;
        } else {
            r = n;
        }
        return r;
    };

    function formatDate(unixDate) {
        const date = new Date(unixDate * 1000);
        return `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`;
    };

    const formattedDate = formatDate( unixDate );

    return (
        <span className='date'>{formattedDate}</span>
    );
};

export const CurrencyFormat = ({ currency }) => {
    const formattedCurrency = '$' + parseFloat( currency ).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return (
        <span className='currency'>{formattedCurrency}</span>
    );
};

export const CardSlug = ({ card }) => {
    let cardSlug = card.card_name.replace(/\W+/g, '-').toLowerCase()
    cardSlug = cardSlug.replace(/\W$/, '').toLowerCase();
    cardSlug = card.id + '-' + cardSlug;

    return ( <NavLink to={`/card/${cardSlug}`}>{card.card_name_display ? card.card_name_display : card.card_name }</NavLink> );
};