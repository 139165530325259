import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import LineGraph from "./core/lineChart";
import { CurrencyFormat } from './core/format'

export default class Card extends Component {
    constructor({ props, match }) {
      super(props);

      this.state = {
        error: null,
        isLoaded: false,
        cardId: match.params.cardId,
        card: {},
        prices: [],
        buyPrice: null,
        showPrices: false
      };
    };

    componentDidMount() {
      const cardId = this.state.cardId;

      this.getCardAndPricing( cardId );
    }
  
    componentWillUnmount() {
      // reset the state
      this.setState({
        error: null,
        isLoaded: false,
        cardId: null,
        card: {},
        prices: [],
        showPrices: false,
      });
    }

    // componentDidUpdate() {
    //   const cardId = this.state.cardId;

    //   console.log(`%c cardId: ${cardId}`, 'background-color: purple; color: #fff;' );

    //   this.getCardAndPricing( cardId );
    // }

    getCardAndPricing( cardId ) {
      fetch("//pokemontcgprices.com/api/card/" + cardId)
      .then(res => res.json())
      .then(
        (result) => {
          if( result.length === 1 ) {
            // update html title
            document.title = 'PokemonTCGPrices.com - ' + result[0].card_name + ' - ' + result[0].set_name;

            this.setState({ card: result[0] });

            fetch("//pokemontcgprices.com/api/price/" + result[0].productId )
            .then(res => res.json())
            .then(
              (result) => {
                // find the buyPrice


                // udate the state { isLoaded, prices }
                this.setState({ isLoaded: true, prices: result, showPrices: true });
              },
              (error) => {
                this.setState({ isLoaded: true, error });
              }
            )
          } else {
            // udate the card { isLoaded, error }
            this.setState({ isLoaded: true, error: 'to many cards returned' });
          }
        },
        (error) => {
          // udate the card { isLoaded, error }
          this.setState({ isLoaded: true, error });
        }
      )   
    }

    // componentWillUnmount() {
    //   this.setState( { isLoaded: false, cardId: '', card: {}, prices: [] } );
    // }

    render() {
      const { error, isLoaded, card, prices, buyPrice } = this.state;

      // console.log( prices );

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="pokemon_loader_container">
              <div className="pokemon_loader">
                <div className="pokemon_loader_center"></div>
              </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="row">
              <div className="col">
                <h1>{ card.card_name }</h1>
              </div>
            </div>
            <div className="row">

              <div className="col col-12 col-lg-4 col-xl-3">
                <table className="table card_table">
                  <tbody>
                    <tr className="card_image">
                      <td colSpan='2'><img src={`//static.pokemontcgprices.com/card_images/${ card.card_image }`} alt={ card.card_name } /></td>
                    </tr>
                    <tr className="card_name">
                      <td>Name:</td>
                      <td>{ card.card_name }</td>
                    </tr>
                    <tr className="card_rarity">
                      <td>Rarity:</td>
                      <td>{ card.card_rarity }</td>
                    </tr>
                    <tr className="card_number">
                      <td>Card Number:</td>
                      <td>{ card.card_number }</td>
                    </tr>
                    <tr className="card_set">
                      <td>Set:</td>
                      <td>
                        {/* <img className="set_icon" src={`//static.pokemontcgprices.com/set_icons/${card.set_icon}`} alt={card.set_name} /> */}
                        <NavLink to={`/set/${card.set_table_name}`}>{card.set_name}</NavLink>
                      </td>
                    </tr>
                    { card.card_text &&
                      <tr className="card_text">
                        <td>Card Text:</td>
                        <td>
                          <div dangerouslySetInnerHTML={{ __html: card.card_text }}></div>
                        </td>
                      </tr>
                    }
                    <tr classNamee="buy_card">
                      <td colSpan="2">
                        <div className="btn buyNow">
                          <a href={`${card.tcg_url}?utm_campaign=affiliate&utm_medium=PokemonTCGPrices&utm_source=PokemonTCGPrices`} rel="external" target="_blank">Buy on TCGplayer.com
                            { card.tcg_lowPrice &&
                              <span> | <CurrencyFormat currency={card.tcg_lowPrice} /></span>
                            }
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col col-12 col-lg-8 col-xl-9">
                {/* console.log( this.state.showPrices ) */}
                { this.state.showPrices &&
                  <LineGraph data={ { card: card, prices: prices } } />
                }
              </div>
            </div>
          </div>
        );
      }
    }
  }