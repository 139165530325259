import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { UnixToDate, UnixToFormattedDate, CurrencyFormat } from './format'

export default class LineGraph extends Component {
    constructor({ props }) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            data: this.props,
            startDate: {
                year: 0,
                month: 0,
                day: 0
            },
            priceRecords: {
                high: { price: null, date: null },
                low: { price: null, date: null }
            },
            showPriceChart: false,
            marketPriceData: [],
            highPriceDataset: [],
            midPriceDataset: [],
            lowPriceDataset: [],
            firstEdStartDate: {
                year: 0,
                month: 0,
                day: 0
            },
            firstEdPriceRecords: {
                high: { price: null, date: null },
                low: { price: null, date: null }
            },
            showFirstEditionChart: false,
            firstEdMarketPriceData: [],
            firstEdHighPriceDataset: [],
            firstEdMidPriceDataset: [],
            firstEdlowPriceDataset: [],
            holofoilStartDate: {
                year: 0,
                month: 0,
                day: 0
            },
            holofoilPriceRecords: {
                high: { price: null, date: null },
                low: { price: null, date: null }
            },
            showHolofoilChart: false,
            holofoilMarketPriceData: [],
            holofoilHighPriceDataset: [],
            holofoilMidPriceDataset: [],
            holofoilLowPriceDataset: [],
            reverseHolofoilStartDate: {
                year: 0,
                month: 0,
                day: 0
            },
            reverseHolofoilPriceRecords: {
                high: { price: null, date: null },
                low: { price: null, date: null }
            },
            showReverseHolofoilChart: false,
            reverseHolofoilMarketPriceData: [],
            reverseHolofoilHighPriceDataset: [],
            reverseHolofoilMidPriceDataset: [],
            reverseHolofoilLowPriceDataset: [],
        };
    };

    chartRef = React.createRef();
    
    getDataset( data, pricePoint ) {
        let returnData = [];
        for( let x = 0; x < data.length; x += 1 ) {
            returnData.push( data[x][pricePoint] );
        }
        // console.log(`%c returnData: ${returnData}`, 'background-color: purple; color: #fff;' );
        return returnData;
    };

    getStartDate( prices ) {
        // console.log( { prices: prices } )
        
        // let startDate = prices.market[0][0];
        // set the start date to today
        let startDate = new Date().getTime();

        // console.log(`%c pricing: ${startDate}`, 'background-color: purple; color: #fff;' );

        for( let x = 0; x < prices.market.length; x += 1 ) {
           if( startDate > prices.market[x][0] ) { startDate = prices.market[x][0]; }
        }
        for( let x = 0; x < prices.high.length; x += 1 ) {
            if( startDate > prices.high[x][0] ) { startDate = prices.high[x][0]; }
        }
        for( let x = 0; x < prices.mid.length; x += 1 ) {
            if( startDate > prices.mid[x][0] ) { startDate = prices.mid[x][0]; }
        }
        for( let x = 0; x < prices.low.length; x += 1 ) {
            if( startDate > prices.low[x][0] ) { startDate = prices.low[x][0]; }
        }

        // console.log(`%c pricing: ${startDate}`, 'background-color: purple; color: #fff;' );

        // now that we have the earliest date, lets get the year, month, and date numbers
        let date = new Date( startDate * 1000 );
        // console.log(`%c date.getFullYear(): ${date.getFullYear()}`, 'background-color: purple; color: #fff;' );

        return {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate()
        };
    };

    getPriceRecords( pricing ) {
        let highPrice=0, highDate, lowPrice=100000000, lowDate;

        for( let x = 0; x < pricing.length; x += 1 ) {
            if( pricing[x][1] >= highPrice ) {
                highDate = pricing[x][0];
                highPrice = pricing[x][1];
            }
            if( pricing[x][1] <= lowPrice ) {
                lowDate = pricing[x][0];
                lowPrice = pricing[x][1];
            }
        }

        return { high: { price: highPrice, date: highDate }, low: { price: lowPrice, date: lowDate } }
    }

    componentDidMount() {
        // console.log( "LineChart Mounted" );
        // console.log( this.state );
        const startDate = this.getStartDate( this.props.data.prices.pricing );

        // now check for reverse holofoil pricing
        if( this.props.data.prices.pricing.low.length > 0 && this.props.data.prices.pricing.mid.length > 0 && this.props.data.prices.pricing.market.length > 0 ) {
            const marketPriceDataset = this.getDataset( this.props.data.prices.pricing.market, 1 );
            const highPriceDataset = this.getDataset( this.props.data.prices.pricing.high, 1 );
            const midPriceDataset = this.getDataset( this.props.data.prices.pricing.mid, 1 );
            const lowPriceDataset = this.getDataset( this.props.data.prices.pricing.low, 1 );

            /* get the high and low price */
            const priceRecords = this.getPriceRecords( this.props.data.prices.pricing.market );

            this.setState({
                showPriceChart: true,
                marketPriceData: marketPriceDataset,
                highPriceDataset: highPriceDataset,
                midPriceDataset: midPriceDataset,
                lowPriceDataset: lowPriceDataset,
                startDate: startDate,
                priceRecords: priceRecords,
                // plotOptionsDataset: plotOptionsUnixDates,
            });
        }

        // now check for first edition
        if( this.props.data.prices.firstEditionPricing.low.length > 0 && this.props.data.prices.firstEditionPricing.mid.length > 0 && this.props.data.prices.firstEditionPricing.market.length > 0 ) {
            const firstEdStartDate = this.getStartDate( this.props.data.prices.firstEditionPricing );

            const firstEdMarketPriceData = this.getDataset( this.props.data.prices.firstEditionPricing.market, 1 );
            const firstEdHighPriceDataset = this.getDataset( this.props.data.prices.firstEditionPricing.high, 1 );
            const firstEdMidPriceDataset = this.getDataset( this.props.data.prices.firstEditionPricing.mid, 1 );
            const firstEdLowPriceDataset = this.getDataset( this.props.data.prices.firstEditionPricing.low, 1 );
    
            /* get the high and low price */
            const firstEdPriceRecords = this.getPriceRecords( this.props.data.prices.firstEditionPricing.market );

            this.setState({
                showFirstEditionChart: true,
                firstEdStartDate: firstEdStartDate,
                firstEdMarketPriceData: firstEdMarketPriceData,
                firstEdHighPriceDataset: firstEdHighPriceDataset,
                firstEdMidPriceDataset: firstEdMidPriceDataset,
                firstEdLowPriceDataset: firstEdLowPriceDataset,
                firstEdPriceRecords: firstEdPriceRecords,
            });
        }

        // now check for holofoil pricing
        if( this.props.data.prices.holofoilPricing.low.length > 0 && this.props.data.prices.holofoilPricing.mid.length > 0 && this.props.data.prices.holofoilPricing.market.length > 0 ) {
            const holofoilStartDate = this.getStartDate( this.props.data.prices.holofoilPricing );

            const holofoilMarketPriceData = this.getDataset( this.props.data.prices.holofoilPricing.market, 1 );
            const holofoilHighPriceDataset = this.getDataset( this.props.data.prices.holofoilPricing.high, 1 );
            const holofoilMidPriceDataset = this.getDataset( this.props.data.prices.holofoilPricing.mid, 1 );
            const holofoilLowPriceDataset = this.getDataset( this.props.data.prices.holofoilPricing.low, 1 );
    
            /* get the high and low price */
            const holofoilPriceRecords = this.getPriceRecords( this.props.data.prices.holofoilPricing.market );

            this.setState({
                showHolofoilChart: true,
                holofoilStartDate: holofoilStartDate,
                holofoilMarketPriceData: holofoilMarketPriceData,
                holofoilHighPriceDataset: holofoilHighPriceDataset,
                holofoilMidPriceDataset: holofoilMidPriceDataset,
                holofoilLowPriceDataset: holofoilLowPriceDataset,
                holofoilPriceRecords: holofoilPriceRecords,
            });
        }

        // now check for reverse holofoil pricing
        if( this.props.data.prices.reverseHolofoilPricing.low.length > 0 && this.props.data.prices.reverseHolofoilPricing.mid.length > 0 && this.props.data.prices.reverseHolofoilPricing.market.length > 0 ) {
            const reverseHolofoilStartDate = this.getStartDate( this.props.data.prices.reverseHolofoilPricing );

            const reverseHolofoilMarketPriceData = this.getDataset( this.props.data.prices.reverseHolofoilPricing.market, 1 );
            const reverseHolofoilHighPriceDataset = this.getDataset( this.props.data.prices.reverseHolofoilPricing.high, 1 );
            const reverseHolofoilMidPriceDataset = this.getDataset( this.props.data.prices.reverseHolofoilPricing.mid, 1 );
            const reverseHolofoilLowPriceDataset = this.getDataset( this.props.data.prices.reverseHolofoilPricing.low, 1 );
    
            /* get the high and low price */
            const reverseHolofoilPriceRecords = this.getPriceRecords( this.props.data.prices.reverseHolofoilPricing.market );

            this.setState({
                showReverseHolofoilChart: true,
                reverseHolofoilStartDate: reverseHolofoilStartDate,
                reverseHolofoilMarketPriceData: reverseHolofoilMarketPriceData,
                reverseHolofoilHighPriceDataset: reverseHolofoilHighPriceDataset,
                reverseHolofoilMidPriceDataset: reverseHolofoilMidPriceDataset,
                reverseHolofoilLowPriceDataset: reverseHolofoilLowPriceDataset,
                reverseHolofoilPriceRecords: reverseHolofoilPriceRecords,
            });
        }
        

    }

    render() {
        // 60 seconds * 60 minutes * 24 hours * 1000 miliseconds
        // const tickInterval = 60 * 60 * 24 * 1000

        const options = {
            /* title: {
              text: this.props.data.card.card_name + ' - ' + this.props.data.card.set_name,
            }, */
            title: false,
            colors: ['#cc0000', '#6f42c1', '#42b983', '#333'],
            subtitle: {
                text: 'Source: tcgplayer.com'
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                valuePrefix: '$',
                valueSuffix: ' USD'
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function() {
                        return '$'+ this.value;
                    }
                }
            },
            xAxis: {
                type: 'datetime',
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC( this.state.startDate.year, this.state.startDate.month, this.state.startDate.day ),
                    pointInterval: 24 * 3600 * 1000, // one day
                    label: {
                        connectorAllowed: true
                    },
                    marker: {
                        enabled: false
                    }
                },
                xrange: {
                    // shared options for all xrange series
                }
            },
            series: [
                {
                    name: 'High Price',
                    visible: false,
                    data: this.state.highPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Market Price',
                    data: this.state.marketPriceData,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Mid Price',
                    data: this.state.midPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Low Price',
                    visible: false,
                    data: this.state.lowPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
            ],
          }

          const firstEdOptions = {
            /* title: {
                text: this.props.data.card.card_name + ' - ' + this.props.data.card.set_name + ' (First Edition)',
            }, */
            title: false,
            colors: ['#cc0000', '#6f42c1', '#42b983', '#333'],
            subtitle: {
                text: 'Source: tcgplayer.com'
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                valuePrefix: '$',
                valueSuffix: ' USD'
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function() {
                        return '$'+ this.value;
                    }
                }
            },
            xAxis: {
                type: 'datetime',
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC( this.state.firstEdStartDate.year, this.state.firstEdStartDate.month, this.state.firstEdStartDate.day ),
                    pointInterval: 24 * 3600 * 1000, // one day
                    label: {
                        connectorAllowed: true
                    },
                    marker: {
                        enabled: false
                    }
                },
                xrange: {
                    // shared options for all xrange series
                }
            },
            series: [
                {
                    name: 'High Price',
                    visible: false,
                    data: this.state.firstEdHighPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Market Price',
                    data: this.state.firstEdMarketPriceData,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Mid Price',
                    data: this.state.firstEdMidPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Low Price',
                    visible: false,
                    data: this.state.firstEdLowPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
            ],
          }

          const holofoilPricingOptions = {
            /* title: {
                text: this.props.data.card.card_name + ' - ' + this.props.data.card.set_name + ' (Holofoil)',
            }, */
            title: false,
            colors: ['#cc0000', '#6f42c1', '#42b983', '#333'],
            subtitle: {
                text: 'Source: tcgplayer.com'
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                valuePrefix: '$',
                valueSuffix: ' USD'
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function() {
                        return '$'+ this.value;
                    }
                }
            },
            xAxis: {
                type: 'datetime',
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC( this.state.holofoilStartDate.year, this.state.holofoilStartDate.month, this.state.holofoilStartDate.day ),
                    pointInterval: 24 * 3600 * 1000, // one day
                    label: {
                        connectorAllowed: true
                    },
                    marker: {
                        enabled: false
                    }
                },
                xrange: {
                    // shared options for all xrange series
                }
            },
            series: [
                {
                    name: 'High Price',
                    visible: false,
                    data: this.state.holofoilHighPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Market Price',
                    data: this.state.holofoilMarketPriceData,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Mid Price',
                    data: this.state.holofoilMidPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Low Price',
                    visible: false,
                    data: this.state.holofoilLowPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
            ],
          }

          const reverseHolofoilOptions = {
            /* title: {
                text: this.props.data.card.card_name + ' - ' + this.props.data.card.set_name + ' (Reverse Holofoil)',
            }, */
            title: false,
            colors: ['#cc0000', '#6f42c1', '#42b983', '#333'],
            subtitle: {
                text: 'Source: tcgplayer.com'
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                valuePrefix: '$',
                valueSuffix: ' USD'
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function() {
                        return '$'+ this.value;
                    }
                }
            },
            xAxis: {
                type: 'datetime',
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC( this.state.reverseHolofoilStartDate.year, this.state.reverseHolofoilStartDate.month, this.state.reverseHolofoilStartDate.day ),
                    pointInterval: 24 * 3600 * 1000, // one day
                    label: {
                        connectorAllowed: true
                    },
                    marker: {
                        enabled: false
                    }
                },
                xrange: {
                    // shared options for all xrange series
                }
            },
            series: [
                {
                    name: 'High Price',
                    visible: false,
                    data: this.state.reverseHolofoilHighPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Market Price',
                    data: this.state.reverseHolofoilMarketPriceData,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Mid Price',
                    data: this.state.reverseHolofoilMidPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
                {
                    name: 'Low Price',
                    visible: false,
                    data: this.state.reverseHolofoilLowPriceDataset,
                    dataLabels: {
                        // enabled: true,
                        formatter: function() {
                            return '$'+ Highcharts.numberFormat(this.y, 2);
                        }
                    }
                },
            ],
          }

        // prime variables for Price Records and Current Price
        let price_records = false, price_records_first_ed = false, price_records_holofoil = false, price_records_reverse_holofoil = false;
        let price_current = false, price_current_first_ed = false, price_current_holofoil = false, price_current_reverse_holofoil = false;

        // price records for normal cards
        if( this.state.priceRecords.high.price != null && this.state.priceRecords.low.price != null ) {
            price_records = <div className="price_records">
                                <div className="high">
                                    Market High <CurrencyFormat currency={this.state.priceRecords.high.price} /> on <UnixToFormattedDate unixDate={this.state.priceRecords.high.date} />
                                </div>
                                <div className="low">
                                    Market Low <CurrencyFormat currency={this.state.priceRecords.low.price} /> on <UnixToFormattedDate unixDate={this.state.priceRecords.low.date} />
                                </div>
                            </div>
        }
        // today's prices for normal cards
        if( this.props.data.prices.pricing.low.length > 0 && this.props.data.prices.pricing.mid.length > 0 && this.props.data.prices.pricing.market.length > 0 ) {
            price_current = <div className="price_current">
                                <div className="title">
                                    Current Prices
                                </div>
                                <div className="price_current_container row">
                                    <div className="high col col-12 col-sm-3">
                                        <div className="price_title">High</div>
                                        <CurrencyFormat currency={ this.props.data.prices.pricing.high[ ( this.props.data.prices.pricing.high.length - 1 ) ][1] } />
                                    </div>
                                    <div className="market col col-12 col-sm-3">
                                        <div className="price_title">Market</div>
                                        <CurrencyFormat currency={ this.props.data.prices.pricing.market[ ( this.props.data.prices.pricing.market.length - 1 ) ][1] } />
                                    </div>
                                    <div className="mid col col-12 col-sm-3">
                                        <div className="price_title">Mid</div>
                                        <CurrencyFormat currency={ this.props.data.prices.pricing.mid[ ( this.props.data.prices.pricing.mid.length - 1 ) ][1] } />
                                    </div>
                                    <div className="low col col-12 col-sm-3">
                                        <div className="price_title">Low</div>
                                        <CurrencyFormat currency={ this.props.data.prices.pricing.low[ ( this.props.data.prices.pricing.low.length - 1 ) ][1] } />
                                    </div>
                                </div>
                            </div>
        }

        // price records for first edition cards
        if( this.state.firstEdPriceRecords.high.price != null && this.state.firstEdPriceRecords.low.price != null ) {
            price_records_first_ed = <div className="price_records">
                                <div className="high">
                                    Market High <CurrencyFormat currency={this.state.firstEdPriceRecords.high.price} /> on <UnixToDate unixDate={this.state.firstEdPriceRecords.high.date} />
                                </div>
                                <div className="low">
                                    Market Low <CurrencyFormat currency={this.state.firstEdPriceRecords.low.price} /> on <UnixToDate unixDate={this.state.firstEdPriceRecords.low.date} />
                                </div>
                            </div>
        }
        // today's prices for first edition cards
        if( this.props.data.prices.firstEditionPricing.low.length > 0 && this.props.data.prices.firstEditionPricing.mid.length > 0 && this.props.data.prices.firstEditionPricing.market.length > 0 ) {
            price_current_first_ed = <div className="price_current">
                                <div className="title">
                                    Current First Edition Prices
                                </div>
                                <div className="price_current_container row">
                                    <div className="high col col-12 col-sm-3">
                                        <div className="price_title">High</div>
                                        <CurrencyFormat currency={ this.props.data.prices.firstEditionPricing.high[ ( this.props.data.prices.firstEditionPricing.high.length - 1 ) ][1] } />
                                    </div>
                                    <div className="market col col-12 col-sm-3">
                                        <div className="price_title">Market</div>
                                        <CurrencyFormat currency={ this.props.data.prices.firstEditionPricing.market[ ( this.props.data.prices.firstEditionPricing.market.length - 1 ) ][1] } />
                                    </div>
                                    <div className="mid col col-12 col-sm-3">
                                        <div className="price_title">Mid</div>
                                        <CurrencyFormat currency={ this.props.data.prices.firstEditionPricing.mid[ ( this.props.data.prices.firstEditionPricing.mid.length - 1 ) ][1] } />
                                    </div>
                                    <div className="low col col-12 col-sm-3">
                                        <div className="price_title">Low</div>
                                        <CurrencyFormat currency={ this.props.data.prices.firstEditionPricing.low[ ( this.props.data.prices.firstEditionPricing.low.length - 1 ) ][1] } />
                                    </div>
                                </div>
                            </div>
        }

        // price records for holofooil cards
        if( this.state.holofoilPriceRecords.high.price != null && this.state.holofoilPriceRecords.low.price != null ) {
            price_records_holofoil =  <div className="price_records">
                                <div className="high">
                                    Market High <CurrencyFormat currency={this.state.holofoilPriceRecords.high.price} /> on <UnixToDate unixDate={this.state.holofoilPriceRecords.high.date} />
                                </div>
                                <div className="low">
                                    Market Low <CurrencyFormat currency={this.state.holofoilPriceRecords.low.price} /> on <UnixToDate unixDate={this.state.holofoilPriceRecords.low.date} />
                                </div>
                            </div>
        }
        // today's prices for holofooil cards
        if( this.props.data.prices.holofoilPricing.low.length > 0 && this.props.data.prices.holofoilPricing.mid.length > 0 && this.props.data.prices.holofoilPricing.market.length > 0 ) {
            price_current_holofoil = <div className="price_current">
                                <div className="title">
                                    Current Holofoil Prices
                                </div>
                                <div className="price_current_container row">
                                    <div className="high col col-12 col-sm-3">
                                        <div className="price_title">High</div>
                                        <CurrencyFormat currency={ this.props.data.prices.holofoilPricing.high[ ( this.props.data.prices.holofoilPricing.high.length - 1 ) ][1] } />
                                    </div>
                                    <div className="market col col-12 col-sm-3">
                                        <div className="price_title">Market</div>
                                        <CurrencyFormat currency={ this.props.data.prices.holofoilPricing.market[ ( this.props.data.prices.holofoilPricing.market.length - 1 ) ][1] } />
                                    </div>
                                    <div className="mid col col-12 col-sm-3">
                                        <div className="price_title">Mid</div>
                                        <CurrencyFormat currency={ this.props.data.prices.holofoilPricing.mid[ ( this.props.data.prices.holofoilPricing.mid.length - 1 ) ][1] } />
                                    </div>
                                    <div className="low col col-12 col-sm-3">
                                        <div className="price_title">Low</div>
                                        <CurrencyFormat currency={ this.props.data.prices.holofoilPricing.low[ ( this.props.data.prices.holofoilPricing.low.length - 1 ) ][1] } />
                                    </div>
                                </div>
                            </div>
        }

        // price records for reverse holofooil cards
        if( this.state.reverseHolofoilPriceRecords.high.price != null && this.state.reverseHolofoilPriceRecords.low.price != null ) {
            price_records_reverse_holofoil =  <div className="price_records">
                                <div className="high">
                                    Market High <CurrencyFormat currency={this.state.reverseHolofoilPriceRecords.high.price} /> on <UnixToDate unixDate={this.state.reverseHolofoilPriceRecords.high.date} />
                                </div>
                                <div className="low">
                                    Market Low <CurrencyFormat currency={this.state.reverseHolofoilPriceRecords.low.price} /> on <UnixToDate unixDate={this.state.reverseHolofoilPriceRecords.low.date} />
                                </div>
                            </div>
        }
        // today's prices for reverse holofooil cards
        if( this.props.data.prices.reverseHolofoilPricing.low.length > 0 && this.props.data.prices.reverseHolofoilPricing.mid.length > 0 && this.props.data.prices.reverseHolofoilPricing.market.length > 0 ) {
            price_current_reverse_holofoil = <div className="price_current">
                                <div className="title">
                                    Current Reverse Holofoil Prices
                                </div>
                                <div className="price_current_container row">
                                    <div className="high col col-12 col-sm-3">
                                        <div className="price_title">High</div>
                                        <CurrencyFormat currency={ this.props.data.prices.reverseHolofoilPricing.high[ ( this.props.data.prices.reverseHolofoilPricing.high.length - 1 ) ][1] } />
                                    </div>
                                    <div className="market col col-12 col-sm-3">
                                        <div className="price_title">Market</div>
                                        <CurrencyFormat currency={ this.props.data.prices.reverseHolofoilPricing.market[ ( this.props.data.prices.reverseHolofoilPricing.market.length - 1 ) ][1] } />
                                    </div>
                                    <div className="mid col col-12 col-sm-3">
                                        <div className="price_title">Mid</div>
                                        <CurrencyFormat currency={ this.props.data.prices.reverseHolofoilPricing.mid[ ( this.props.data.prices.reverseHolofoilPricing.mid.length - 1 ) ][1] } />
                                    </div>
                                    <div className="low col col-12 col-sm-3">
                                        <div className="price_title">Low</div>
                                        <CurrencyFormat currency={ this.props.data.prices.reverseHolofoilPricing.low[ ( this.props.data.prices.reverseHolofoilPricing.low.length - 1 ) ][1] } />
                                    </div>
                                </div>
                            </div>
        }

        return (
            <div className="price_chart_container">
                { this.state.showPriceChart &&
                    <div className="chart price_chart">
                        { price_current }
                        { price_records }
                        <HighchartsReact highcharts={Highcharts} options={options} />
                        <div className="chart_footer">^ select other price points ^</div>
                    </div>
                }

                {/* partner=MTGSTCKS&utm_campaign=affiliate&utm_source=MTGSTCKS&utm_medium=pikastocks */}

                { this.state.showFirstEditionChart &&
                    <div className="chart first_edition_price_chart">
                        { price_current_first_ed }
                        { price_records_first_ed }
                        <HighchartsReact highcharts={Highcharts} options={firstEdOptions} />
                        <div className="chart_footer">^ select other price points ^</div>
                    </div>
                }

                { this.state.showHolofoilChart &&
                    <div className="chart holofiol_price_chart">
                        { price_current_holofoil }
                        { price_records_holofoil }
                        <HighchartsReact highcharts={Highcharts} options={holofoilPricingOptions} />
                        <div className="chart_footer">^ select other price points ^</div>
                    </div>
                }

                { this.state.showReverseHolofoilChart &&
                    <div className="chart reverse_holofiol_price_chart">
                        { price_current_reverse_holofoil }
                        { price_records_reverse_holofoil }
                        <HighchartsReact highcharts={Highcharts} options={reverseHolofoilOptions} />
                        <div className="chart_footer">^ select other price points ^</div>
                    </div>
                }
            </div>
        )
    }
}