import React from 'react';

let timer, timeoutVal = 500;

class Search extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        error: null,
        isLoaded: false,
        searchBoxValue: '',
        searchResults: [],
        visible: false
      };

      this.closeSearchBox = this.closeSearchBox.bind(this);
    };

    search ( val ) {
      fetch(`//pokemontcgprices.com/api/search/${val}` )
      .then(res => res.json())
      .then(
        (result) => {
          // set the local variable
          this.setState({
            isLoaded: true,
            searchResults: result,
            visible: true
          });
        },

        (error) => {
          this.setState({ isLoaded: true, error });
        }
      )
    };

    onChangeHandler( e ) {
      // clear the other timeout
      window.clearTimeout( timer );
      // update the state
      this.setState({ searchBoxValue: e.target.value });

      if( e.target.value.length > 0 ) {
        // set the time
        timer = window.setTimeout(() => {
            this.search(e.target.value);
        }, timeoutVal);
      } else {
        this.closeSearchBox();
      }
    };

    closeSearchBox() {
      // console.log( "attempting to close search box" );
      this.setState({
        searchResults: [],
        visible: false
      });
    };

  
    render() {
      return (
        <div className="search-container" tabIndex="0" onBlur={ this.collapse } >
            <form className="form-inline my-2 my-lg-0">
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={this.state.searchBoxValue} onChange={e => this.onChangeHandler(e)} />
                { this.state.visible &&
                    <div className="search-results">
                        {this.state.searchResults.map(searchResult =>
                            <div className="search-result" key={searchResult.slug}>
                                <a href={`/card/${searchResult.slug}`}>
                                    {searchResult.card_name_display ? searchResult.card_name_display : searchResult.card_name } ({searchResult.set_name})
                                </a>
                              {/*
                                <NavLink to={`/card/${searchResult.productId}`} onClick={this.closeSearchBox}>
                                    {searchResult.card_name_display ? searchResult.card_name_display : searchResult.card_name } ({searchResult.set_name})
                                </NavLink>
                              */}
                            </div>
                        )}
                    </div>
                }

            </form>

            {/* <div className="searchglass-container">
                <i className="fas fa-search"></i>
            </div> */}
        </div>
      );

    }
  }

  export default Search;
