import React from 'react';
import { CardSlug } from './format'
import { NavLink } from "react-router-dom";

const useSortableData = (cards, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedCards = React.useMemo(() => {
    // console.log('================================================================');
    // console.log(`cards type in SetTable - ${typeof cards} | cards.length - ${cards.length}`);
    // console.log(cards);
    // console.log('================================================================');

    let sortableCards = [...cards];
    if (sortConfig !== null) {
      sortableCards.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCards;
  }, [cards, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { cards: sortedCards, requestSort, sortConfig };
};

const Table = (props) => {
  // let cards = props.tableData.cards;
  const { cards, requestSort, sortConfig } = useSortableData( props.tableData );
  
  // console.log(`%c typeof cards in Table - ${typeof cards} | typeof props.tableData.cards in Table - ${typeof props.tableData.cards}`, 'background-color: yellow; color: #000;');

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <table className="table set_table sort_table">
      <thead>
        <tr className="sort_table_header">
            <th>
              <span onClick={() => requestSort('card_name')} className={"set_table_column_header " + getClassNamesFor('card_name')}>Name Sort <span className="sort_image"></span></span>
            </th>
            <th>
              <span onClick={() => requestSort('card_rarity')} className={"set_table_column_header " + getClassNamesFor('card_rarity')}>Rarity<span className="sort_image"></span></span>
            </th>
            <th>
              <span onClick={() => requestSort('card_number')} className={"set_table_column_header " + getClassNamesFor('card_number')}>Card Number<span className="sort_image"></span></span>
            </th>
            <th>
              <span onClick={() => requestSort('tcg_marketPrice')} className={"set_table_column_header " + getClassNamesFor('tcg_marketPrice')}>Market Price<span className="sort_image"></span></span>
            </th>
            <th>
              <span onClick={() => requestSort('tcg_lowPrice')} className={"set_table_column_header " + getClassNamesFor('tcg_lowPrice')}>Low Price<span className="sort_image"></span></span>
            </th>
            <th>
              <span onClick={() => requestSort('tcg_midPrice')} className={"set_table_column_header " + getClassNamesFor('tcg_midPrice')}>Mid Price<span className="sort_image"></span></span>
            </th>
        </tr>
      </thead>
      <tbody>
        {cards.map(card =>
          <tr key='{card.slug}'>
            <td><NavLink to={`/card/${card.slug}`}>{card.card_name_display ? card.card_name_display : card.card_name }</NavLink></td>
            <td>{ card.card_rarity }</td>
            <td>{ card.card_number }</td>
            <td>${ parseFloat(card.tcg_marketPrice).toFixed(2) }</td>
            <td>${ parseFloat(card.tcg_lowPrice).toFixed(2) }</td>
            <td>${ parseFloat(card.tcg_midPrice).toFixed(2) }</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default function SetTable( cards ) {
  return (
      <div>
        { /* console.log(`%c typeof tableData.length - ${cards.cards.length}`, 'background-color: yellow; color: #000;') */ }
        <Table tableData={cards.cards} />
      </div>
  );
}