import React from 'react';
import { UnixToDate } from './format'
import { NavLink } from "react-router-dom";

const useSortableData = (sets, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedSets = React.useMemo(() => {
    // console.log('================================================================');
    // console.log(`sets type in SetTable - ${typeof sets} | sets.length - ${sets.length}`);
    // console.log(sets);
    // console.log('================================================================');

    let sortableSets = [...sets];
    if (sortConfig !== null) {
      sortableSets.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSets;
  }, [sets, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { sets: sortedSets, requestSort, sortConfig };
};

const Table = (props) => {
  // let sets = props.tableData.sets;
  const { sets, requestSort, sortConfig } = useSortableData( props.tableData );
  
  // console.log(`%c typeof sets in Table - ${typeof sets} | typeof props.tableData.sets in Table - ${typeof props.tableData.sets}`, 'background-color: yellow; color: #000;');

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <table className="table sets_table sort_table">
        <thead>
            <tr className="sort_table_header">
                <th className="set_image">Set Image</th>
                <th className="set_icon">Set Icon</th>
                <th className="set_name">
                    <span onClick={() => requestSort('set_name')} className={"set_table_column_header " + getClassNamesFor('set_name')}>Set Name <span className="sort_image"></span></span>
                </th>
                <th className="release_date">
                    <span onClick={() => requestSort('release_date')} className={"set_table_column_header " + getClassNamesFor('release_date')}>Release Date <span className="sort_image"></span></span>
                </th>
            </tr>
        </thead>
        <tbody>
            {sets.map(set =>
                <tr key={set.id}>
                    <td className="set_image"><NavLink to={`/set/${set.id}`}><img src={`//static.pokemontcgprices.com/set_images/${set.set_image}`} alt={set.set_name} /></NavLink></td>
                    <td className="set_icon">
                      { set.set_icon &&
                        <img src={`//static.pokemontcgprices.com/set_icons/${set.set_icon}`} alt={set.set_name} />
                      }
                    </td>
                    <td className="set_name"><NavLink to={`/set/${set.id}`}>{set.set_name}</NavLink></td>
                    <td className="release_date"><UnixToDate unixDate={set.release_date} /></td>
                </tr>
            )}
        </tbody>
    </table>
  );
};

export default function SetTable( sets ) {
  return (
      <div>
        { /* console.log(`%c typeof tableData.length - ${sets.sets.length}`, 'background-color: yellow; color: #000;') */ }
        <Table tableData={sets.sets} />
      </div>
  );
}