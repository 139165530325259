import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchBox from "./search";

const Header = () => {
  const [ open, setOpen ] = useState(false);

  return (
    <header>
      <div className='container'>
        <nav id="nav" className="navbar navbar-expand-md">
        <NavLink exact className="logo" activeClassName="active" to="/"><img className="card-logo" src={`//static.pokemontcgprices.com/card-money-logo.png`} alt="Pokemon TCG Prices" /><img className="logo" src={`//static.pokemontcgprices.com/pokemon-tcg-logo-small.png`} alt="Pokemon TCG Prices" /></NavLink>

        <button aria-expanded={open === true ? "true" : "false"} className={`navbar-toggler {open === true ? "active" : ""}`} onClick={ () => setOpen(!open) }>
            <span className="navbar-toggler-icon">
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </span>
          </button>

          <div className={`collapse navbar-collapse ${open ? 'show' : '' }`} id="navbarNav">
            <ul className="navbar-nav">
              <li><NavLink exact activeClassName="active" to="/" onClick={ () => setOpen(false) }>Home</NavLink></li>
              <li><NavLink activeClassName="active" to="/sets" onClick={ () => setOpen(false) }>Sets</NavLink></li>
              <li><NavLink exact activeClassName="active" to="/about" onClick={ () => setOpen(false) }>About</NavLink></li>
            </ul>

            <div className="navbar-nav flex-row ml-md-auto d-md-flex">
              <SearchBox />
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
};
export default Header;