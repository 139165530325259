import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container">
        {/* <div className="row">
          <div className="col">
            <NavLink exact activeClassName="active" to="/">Home</NavLink>
          </div>
        </div> */}

        <div className="row">
          <div className="col">
            <NavLink exact activeClassName="active" to="/"><img className="card-logo" src={`//static.pokemontcgprices.com/card-money-logo.png`} alt="Pokemon TCG Prices" /><img className="logo" src={`//static.pokemontcgprices.com/pokemon-tcg-logo.png`} alt="Pokemon TCG Prices"  /></NavLink>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="copywrite">© { new Date().getFullYear() } Pokemon TCG Prices</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="disclaimer">All Pokemon Images, Trademakes, and Copywrites are owned by <a href="https://www.pokemon.com/us/" target="_blank" rel="noreferrer">The Pokemon Company</a>.</p>
            <p className="disclaimer">Pricing Data has been obtained form <a href="https://www.tcgplayer.com/">TCG Player</a>.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;