import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';

export default class About extends Component {
    constructor({ props, match }) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            slug: match.params.slug,
            title: null,
            content: null,
        };
    };
  
    componentDidMount() {
        // update html title
        document.title = 'PokemonTCGPrices.com - About';

        fetch("//pokemontcgprices.com/api/page/about")
            .then(res => res.json())
            .then(
                (result) => {

                    // console.log(`%c Get the sets!`, 'background-color: orange; color: #fff;' );
                    // console.log( {result} );

                    if( result.length === 1 ) {
                        this.setState({
                            isLoaded: true,
                            title: result[0].title,
                            content: result[0].content,
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            error: 'Page not found.'
                        });
                    }
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                },
            );
    }

    render() {
        const { error, isLoaded, } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div className="pokemon_loader_container">
                    <div className="pokemon_loader">
                      <div className="pokemon_loader_center"></div>
                    </div>
                </div>
              );
        } else {
            return (
                <div>
                    <MetaTags>
                        <title>PokemonTCGPrices.com - About</title>
                        <meta id="meta-description" name="description" content="FAQ about PokemonTCGPrices.com" />
                        <meta id="og-title" property="og:title" content="PokemonTCGPrices.com - About" />
                        <meta id="og-image" property="og:image" content="//static.pokemontcgprices.com/card-money-logo.png" />
                        
                        <link rel="canonical" href="http://pokemontcgprices.com/about" />
                    </MetaTags>

                    <div className="row">
                        <div className="col">
                            <h1>{this.state.title}</h1>
                        </div>
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                </div>
            );
        }
    }
}