import React, { Component } from 'react';
import './App.css';
import Header from './components/layout/header.jsx';
import Footer from './components/layout/footer.jsx';
import Home from './components/home.jsx';
import About from './components/about.jsx';
import Sets from './components/sets.jsx';
import Set from './components/set.jsx';
import Card from './components/card.jsx';
import notFoundPage from './components/notFoundPage.jsx';
import createHistory from 'history/createBrowserHistory'
import GA from './components/core/googleAnalytics'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // withRouter
  // Link
} from "react-router-dom";

// const history = createHistory()
// ReactGA.initialize('UA-192342921-1');
// history.listen((location, action) => {
//     ReactGA.pageview(location.pathname + location.search);
//     console.log(location.pathname)
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sets: [],
      currentSet: null,
      currentCards: [],
    };
  };

  updateSet = ( set ) => {
    this.setState({ currentSet: set });
  };

  updateSets = ( sets ) => {
    this.setState({ sets: sets });
  };

  updateCardsInSets = ( cards, setId ) => {
    let currentSet;
    // get the sets array from the state
    let sets = this.state.sets;
    for( let x = 0; x < sets.length; x += 1 ) {
      if (parseInt(sets[x].id) === parseInt(setId)) {
        sets[x].cards = cards;
        currentSet = sets[x];
      }
    }
    // // update sets
    // this.state.sets = sets;
    // // update sets
    // this.state.currentSet = currentSet;
    // // update currentCards
    // this.state.currentCards = cards;

    this.setState({ sets: sets, currentSet: currentSet, currentCards: cards, });
  }

  // toggleMenu() {
  //   this.state.showMenu = !this.state.showMenu //Flips true/false
  // };

  render() {
    return (
    <Router>
      <Header showMenu={this.state.showMenu} />

      <div className="main">
        { GA.init() && <GA.RouteTracker /> }
        <Switch>
          <Route exact path="/" component={Home} />

          <div className='container'>
            <Route exact path="/about" render={ ({ props, match }) => <About match={match} /> } />

            <Route exact path="/sets">
              <Sets updateSets={this.updateSets} updateSet={this.updateSet} dataToChild={this.state} />
            </Route>

            <Route path="/set/:setId" render={ ({ props, match }) => <Set updateSets={this.updateSets} updateCardsInSets={this.updateCardsInSets} dataToChild={this.state} match={match} /> } />

            <Route path="/card/:cardId" render={ ({ props, match }) => <Card dataToChild={this.state} match={match} /> } />

            {/* <Route path="" component={notFoundPage} /> */}
          </div>
        </Switch>
      </div>

      <Footer />
    </Router>
    );
  };
};

export default App;