import React from 'react';
import SetsTable from './core/setsTable';

class Sets extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        sets: []
      };
      this.updateSetClick = this.sendSetData.bind(this);

      // // Google analytics
      // // Init
      // ReactGA.initialize( "UA-192342921-1" );
      // // GA Tracking
      // ReactGA.set({ page: window.location.pathname });
      // ReactGA.pageview( window.location.pathname )
    };
  
    sendSetsData = ( result ) => {
      // console.log(`%c sending sets to parent`, 'background-color: blue; color: #fff;' );
      this.props.updateSets( result );
    };

    sendSetData = ( result ) => {
      // console.log(`%c sending selected set to parent`, 'background-color: blue; color: #fff;' );
      this.props.updateSet( result );
    };

    componentDidMount() {
      // update html title
      document.title = 'PokemonTCGPrices.com - Sets';

      // first check if we already have the sets, if we do not have the sets, lets fetch them
      if( this.props.dataToChild.sets.length < 1 ) {
        fetch("//pokemontcgprices.com/api/sets/")
          .then(res => res.json())
          .then(
            (result) => {
              // udate the state
              this.setState({ isLoaded: true, sets: result });
              // send the data to the parent
              this.sendSetsData( result );
            },

            (error) => {
              this.setState({ isLoaded: true, error });
            }
          )
      } else {
        this.setState({ isLoaded: true, sets: this.props.dataToChild.sets });
      }
    }
  
    render() {
      const { error, isLoaded, sets } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="pokemon_loader_container">
              <div className="pokemon_loader">
                <div className="pokemon_loader_center"></div>
              </div>
          </div>
        );
      } else {
        return (
          <div>
            <SetsTable sets={sets} />
          </div>
        );
      }
    }
  }

  export default Sets;