import React, {Component} from 'react';
import SetTable from './core/setTable';

export default class Set extends Component {
  constructor({ props, match }) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      cards: [],
      set: {
        set_symbol: '',
        set_name: '',
      },
      sets: [],
      setId: match.params.setId,
    };

    // // Google analytics
    // // Init
    // ReactGA.initialize( "UA-192342921-1" );
    // // GA Tracking
    // ReactGA.set({ page: window.location.pathname });
    // ReactGA.pageview( window.location.pathname )
  };

  // send data to parent
  sendSetsData = ( result ) => {
    this.props.updateSets( result );
  };
  sendCardsData = ( result, setId ) => {
    this.props.updateCardsInSets( result, setId );
  };

  // component functions
  getSetInfo( arrayId ) {
    const sets = this.props.dataToChild.sets;
    for( let x = 0; x < sets.length; x += 1) {
      if( parseInt(x) === parseInt(arrayId) ) {
        return sets[x];
      }
    }
    return null;
  }
  findArraySetId = ( setId ) => {
    const sets = this.props.dataToChild.sets;
    for( let x = 0; x < sets.length; x += 1) {
      if( parseInt(sets[x].id) === parseInt(setId) ) {
        return x;
      }
    }
    return null;
  };
  findTCGSetId = ( setId ) => {
    const sets = this.props.dataToChild.sets;
    for( let x = 0; x < sets.length ; x += 1) {
      if( parseInt(sets[x].id) === parseInt(setId) ) {
        console.log( sets[x].id );
        return sets[x].set_id_tcgplayer;
      }
    }
    return null;
  };

  componentDidMount() {
    let set, setArrayId;
    // console.log(`%c this.props.dataToChild.sets.length - ${this.props.dataToChild.sets.length}`, 'background-color: orange; color: #fff;' );
    // first check if we already have the sets, if we do not have the sets, lets fetch them
    if( this.props.dataToChild.sets.length < 1 ) {
      // console.log(`%c Get the sets!`, 'background-color: orange; color: #fff;' );
      fetch("//pokemontcgprices.com/api/sets/")
        .then(res => res.json())
        .then(
          (result) => {
            // update the state { isLoaded, sets }
            this.setState({ sets: result });
            // send the data to the parent
            this.sendSetsData( result );

            // update the state { set }
            setArrayId = this.findArraySetId( this.state.setId );
            set = this.getSetInfo( setArrayId );
            this.setState({ set: set });

            // update html title
            document.title = 'PokemonTCGPrices.com - ' + set.set_name;

            // now lets get the cards for this set
            this.getCardsInSet();
          },

          (error) => {
            this.setState({ isLoaded: true, error });
          }
        )
    } else {
      this.setState({
        sets: this.props.dataToChild.sets
      });
      // set the current set
      setArrayId = this.findArraySetId( this.state.setId );
      set = this.getSetInfo( setArrayId );
      this.setState({ set: set});

      // now lets get the cards for this set
      this.getCardsInSet();
    }
  }

  getCardsInSet() {
    const setId = this.state.setId;
    const tcgSetId = this.findTCGSetId( setId );

    fetch("//pokemontcgprices.com/api/set/" + tcgSetId)
      .then(res => res.json())
      .then(
        (result) => {
          // set the local variable
          this.setState({
            isLoaded: true,
            cards: result
          });
          // send cards to parent
          this.sendCardsData( result, setId );
        },

        (error) => {
          this.setState({ isLoaded: true, error });
        }
      )
  }
  
  render() {
    const { error, isLoaded, cards, set } = this.state;
    
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className="pokemon_loader_container">
            <div className="pokemon_loader">
              <div className="pokemon_loader_center"></div>
            </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="col">
            <h1>
              { set.set_icon &&
                <img src={`//static.pokemontcgprices.com/set_icons/${set.set_icon}`} alt={set.set_name} />
              } 
              {set.set_name}
            </h1>
          </div>

          <div className="col">
            <SetTable cards={cards} />
          </div>
        </div>
      );
    }
  }
}