import React from 'react';
import { Link } from 'react-router-dom';


class NotFoundPage extends React.Component {
    render(){
        return <div>
            <div className="row">
                <div className="col">
                    <img src="//static.pokemontcgprices.com/404-pallet-town.png" class="page_not_found_image" />
                    <h1>404 Page Not Found</h1>

                    <p style={{textAlign:"center"}}>
                        Ash, you appear to be lost, <Link to="/">head back to Pallet Town</Link>
                    </p>
                </div>
            </div>
          </div>;
    }
}
export default NotFoundPage;